<template>
	<div class="page" id="connexion">
		<img class="back-image" src="../assets/images/connexion/background.webp" />
		<div class="connexion">
			<div class="formulaire">
				<div class="title">PAC Viessmann 2024/2025</div>
				<img class="logo" src="../assets/images/logos/viessmann_wordmark_rgb_1_vitorange.png" />
				

				<h1 v-html="bonjour"></h1>

				<div class="loading" v-if="!$store.state.users_ready">
					<i class="fal fa-spinner fa-spin"></i>
					<p>Initialisation en cours...</p>
				</div>

				<div class="error" v-if="error.length > 0">{{ error }}</div>

				<div class="step" v-if="step1 && $store.state.users_ready && !connexion_success">
					<div class="groupe">
						<label for="email"><i class="fal fa-at"></i></label>
						<input type="email" id="email" v-model="email" name="Email" placeholder="Email" required @keyup="handleStep1()" />
					</div>
				</div>

				<div class="step" v-if="step2 && $store.state.users_ready && !connexion_success">
					<div class="groupe">
						<label for="password"><i class="fal fa-lock"></i></label>
						<input type="password" id="password" v-model="password" name="password" placeholder="••••••••••" required @keyup="handleConnexion()" />
					</div>
					<div class="bottom">
						<div class="motdepasseoublie" v-if="!recup_started" @click="resetpassword()">
							J'ai oublié mon mot de passe
						</div>
						<div class="motdepasseoublie" v-if="is_send">
							Un nouveau mot de passe vient de vous être envoyé sur votre email. Rien reçu ?
							<span @click="resetpassword()">Renvoyer le mail à nouveau</span>
						</div>
						<button type="submit">
							<i class="fal fa-long-arrow-right"></i>
						</button>
					</div>
				</div>

				<div class="connexion_success" v-if="connexion_success">
					<i class="fa fa-spin fa-spinner"></i>
					<p>
						Veuillez patienter quelques secondes pendant le chargement de vos données...
					</p>
				</div>
			</div>

			<div class="indications">
				<strong>Saisissez votre email Viessmann, puis votre mot de passe.</strong>
				<p>
					Si vous avez perdu votre mot de passe ou si c'est votre première connexion, cliquez sur "J'ai oublié mon mot de passe". Vous recevrez un email avec le
					nouveau mot de passe.
				</p>
			</div>
			<div class="version">version {{ version }}</div>
		</div>
		
	</div>
</template>
<script>
import { version } from "../../package.json";
import md5 from "js-md5";
import genetor from "generate-password";
import axios from "axios";

export default {
	name: "Connexion",
	components: {},
	data() {
		return {
			error: "",
			email: "",
			password: "",
			connexion_success: false,
			bonjour: "Connectez-vous à <br /> votre espace personnel",
			version: version,
			step1: true,
			step2: false,
			recup_started: false,
			is_send: false,
			temp_user: {}
		};
	},
	mounted() {
		this.$store.dispatch("getUsers");
	},
	methods: {
		handleStep1() {
			if (this.email.length > 15) {
				let users = this.$store.state.users;
				const found = users.find(user => user.fields.email === this.email);
				if (found) {
					this.error = "";
					this.bonjour = `Bonjour ${found.fields.prenom}. <br />Indiquez votre mot de passe.`;
					this.step2 = true;
					this.temp_user = found;
				} else {
					this.bonjour = "Connectez-vous à <br /> votre espace personnel";
					this.error = "Désolé, cet email est inconnu";
				}
			}
		},
		resetpassword() {
			this.recup_started = true;
			let password = genetor.generate({
				length: 10,
				numbers: true
			});
			let protected_password = md5("viessmann" + password);
			let params = new URLSearchParams();
			params.append("password", password);
			params.append("destinataire", this.email);
			params.append("service", "passwordlost");
			axios.post("https://dev-serveur.fr/mailjet/viessmann/api.php", params)
				.then(res => {
					if (res.data.Messages[0].Status == "success") {
						this.is_send = true;
						this.$store.dispatch("recoverPassword", {
							user: this.temp_user,
							password: protected_password
						});
					}
				})
				.catch(err => {
					console.log(err);
					this.recup_started = false;
				});
		},
		handleConnexion() {
			this.error = "";
			if (this.password.length > 6) {
				let users = this.$store.state.users;
				const found = users.find(user => user.fields.email === this.email && user.fields.mot_de_passe === md5("viessmann" + this.password));
				if (found) {
					this.error = "";
					this.bonjour = `Bonjour ${found.fields.prenom}. <br />Merci. `;
					this.connexion_success = true;
					this.$store.dispatch("connectUser", found);
					setTimeout(() => {
						this.$router.push("/");
					}, 2000);
				} else {
					this.bonjour = "Connectez-vous à <br /> votre espace personnel";
					this.error = "Désolé, le mot de passe ne correspond pas à cet email";
				}
			}
		}
	}
};
</script>

<style lang="scss" scoped>
#connexion {
	width: 100%;
	min-height: 100vh;
	padding: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: white;
	.connexion {
		flex: 0 0 auto;
		width: 100%;
		max-width: 500px;
		margin: 0 auto;
		padding: 30px 30px max(30px, env(safe-area-inset-bottom)) 30px;
		display: flex;
		flex-direction: column;
		color: $black;
		//background: rgba(white, 1);
		//box-shadow: 0px 4px 20px rgba(black, 0.1);
		backdrop-filter: blur(8px);
		justify-content: center;
		align-items: center;
		position: relative;
		@media screen and (max-width: $screen-tablet) {
			flex: 0 0 50%;
			padding: 20px;
			max-width: 400px;
		}
		@media screen and (max-width: $screen-phone) {
			flex: 1;
			max-width: none;
		}
		.formulaire {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;
			padding: 0 10px;
			.logo {
				width: 260px;
				margin-top: -20px;
			}
			.connexion_success,
			.loading {
				font-size: 12px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				gap: 5px;
				margin-bottom: 20px;
				i,
				svg {
					font-size: 20px;
				}
				p {
					margin: 10px 0;
					max-width: 300px;
				}
			}
			h1 {
				font-size: 30px;
				line-height: 34px;
				margin: 10px 0 40px 0;
				text-align: center;
				@media screen and (max-width: $screen-tablet) {
					font-size: 30px;
					line-height: 34px;
				}
				i,
				svg {
					font-size: 20px;
				}
			}
			.step {
				width: 100%;
			}
			.bottom {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				width: 100%;
				max-width: 600px;
				padding: 12px 0;
				.motdepasseoublie {
					font-size: 13px;
					padding: 4px 0;
					text-align: left;
					font-weight: 400;
					opacity: 0.8;
					transition: all 0.3s ease;
					cursor: pointer;
					padding-right: 20px;
					flex: 1;
					&:hover {
						opacity: 1;
					}
					span {
						color: $rouge;
					}
				}
				button[type="submit"] {
					flex: 0 0 auto;
					background: $rouge;
					color: white;
					border-radius: 45px;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 45px;
					height: 45px;
					border: none;
					font-size: 24px;
					transition: all 0.3s ease;
					cursor: pointer;
					&:hover {
						width: 70px;
						background: darken($rouge, 10%);
					}
					i,
					svg {
						transform: translateY(1px);
					}
				}
			}

			.title {
				padding: 5px 10px;
				background: $rouge;
				color: white;
				font-size: 12px;
				font-weight: normal;
				border-radius: 2px;
				.version {
					font-size: 11px;
					padding: 4px 0;
					font-weight: 300;
					color: rgba(white, 0.4);
				}
			}

			.error {
				margin: 10px 0;
				background: rgba($rouge, 0.9);
				font-size: 13px;
				border-radius: 4px;
				padding: 4px 8px;
				color: white;
			}
			.groupe {
				padding: 10px 20px 10px 8px;
				background: white;
				border: solid 1px rgba(black, 0.1);
				transition: all 0.3s ease;
				color: $black;
				width: 100%;
				max-width: 500px;
				display: flex;
				align-items: center;
				margin-bottom: 10px;
				border-radius: 30px;
				@media screen and (max-width: $screen-tablet) {
					padding: 8px;
				}
				&:hover {
					border: solid 1px rgba(white, 0.4);
				}
				i,
				svg {
					flex: 0 0 auto;
					padding: 0 8px 0 20px;
					font-size: 20px;
					@media screen and (max-width: $screen-tablet) {
						font-size: 16px;
						padding: 0 12px;
					}
				}
				input[type="email"],
				input[type="password"] {
					flex: 1;
					border: none;
					background: transparent;
					min-height: 40px;
					padding: 0 10px;
					margin-left: 10px;
					outline: none;
					appearance: none;
					@media screen and (max-width: $screen-tablet) {
						margin-left: 12px;
					}
				}
				input[type="password"] {
					letter-spacing: 4px;
				}
			}
		}
		.indications {
			left: 30px;
			right: 20px;
			font-size: 13px;
			line-height: 20px;
			color: gray;
			margin-top: 30px;
			strong {
				font-size: 16px;
				font-weight: normal;
				margin-bottom: 8px;
				display: block;
				color: black;
			}
		}
		.version {
			font-size: 10px;
			font-weight: normal;
			opacity: 0.5;
			margin-top: 30px;
		}
	}
	.back-image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		filter: blur(20px);
		opacity: .2;
	}
}
</style>
